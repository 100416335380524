import React from "react";

import { Image, Grid, List, Header } from "semantic-ui-react";
import Logo from "../../assets/images/wanchai.jpg";
import Facebook from "../../assets/images/fb.png";
import Mail from "../../assets/images/mail.png";
import map from "../../assets/images/map.jpg";
// import Pointer from "../../assets/images/pointer.png";
// import GoogleMapReact from "google-map-react";

import "./index.scss";

// const AnyReactComponent = ({ text }) => (
//   <img
//     style={{
//       color: "white",
//       padding: "15px 10px",
//       display: "inline-flex",
//       textAlign: "center",
//       alignItems: "center",
//       justifyContent: "center",
//       transform: "translate(-45%, -70%)",
//       width: "80px",
//     }}
//     src={Pointer}
//   ></img>
// );
function AboutUs(props) {
  console.log("[AboutUs]", props);
  return (
    <div className="aboutus">
      <section id="aboutus-first-content" className="content">
        <div className="left">
          <h2>聯絡我們</h2>
          <Grid divided inverted stackable>
            <Grid.Row>
              <Grid.Column width={5}>
                <Image id="logo" src={Logo} size="medium" />
              </Grid.Column>
              <Grid.Column width={9}>
                <Header inverted as="h4" content="灣仔體育總會" />
                <List link inverted>
                  <List.Item as="div">電話：2388 1875</List.Item>
                  <List.Item as="div">傳真：2388 6375</List.Item>
                  <List.Item as="div">
                    地址：香港銅鑼灣摩頓臺(中央圖書館側)
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div className="right">
          <Grid.Row className="social-media">
            <Grid.Column width={4}>
              <List link inverted horizontal>
                <List.Item>
                  <Image
                    src={Facebook}
                    size="mini"
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/wanchaisports",
                        "_blank"
                      );
                    }}
                  />
                </List.Item>
                <List.Item>
                  <Image
                    src={Mail}
                    size="mini"
                    as={"a"}
                    href="mailto:info@wcsf.org.hk"
                  />
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </div>
      </section>
      <section id="aboutus-google-map" className="content">
        <a target="_blsnk" href={"https://goo.gl/maps/aQi5Ht7aahf1myZo9"}>
          <img src={map}></img>
        </a>
        {/* <GoogleMapReact
          defaultCenter={{
            lat: 22.2791722,
            lng: 114.1896428,
          }}
          defaultZoom={18}
        >
          <AnyReactComponent lat={22.279224} lng={114.189651} text="" />
        </GoogleMapReact> */}
      </section>
    </div>
  );
}
export default AboutUs;
