import React, { Component } from "react";
import { Image } from "semantic-ui-react";
import "./index.scss";
import PDFICON from "../../assets/icon/pdf.png";
import img5 from "../../assets/images/5.jpg";
import img6 from "../../assets/images/6.jpg";
import img7 from "../../assets/images/7.jpg";
import PDF1 from "../../assets/pdf/場地申請表格.pdf";
import PDF2 from "../../assets/pdf/場地租用守則.pdf";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="sportfac">
        <section id="sportfac-first-content" className="content">
          <h2>本會設施</h2>
          <p>
            灣仔體育總會摩頓臺會所提供多個設計靈活之多用途場地，適合舉辦各類型的體育活動、訓練課程、典禮、會議、研討會及工作坊等；歡迎各註冊慈善團體、機構、公司及本會會員租用。
          </p>
        </section>
        <section id="sportfac-image-content" className="content">
          <div className="left">
            <div className="images">
              <Image src={img5} />
              <p>多用途活動室</p>
            </div>
            <div className="images">
              <p>舞蹈室</p>
              <Image src={img6} />
            </div>
            <div className="images">
              <Image src={img7} />
              <p>會議室</p>
            </div>
          </div>
          <div className="right">
            <table>
              <thead>
                <tr>
                  <th>設施類型</th>
                  <th>用途</th>
                  <th>可提供之設施</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul>
                      <li>多功能活動室</li>
                      <li>(禮堂)</li>
                      <li>614 平方呎</li>
                      <li>高度：4.6米</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>訓練班</li>
                      <li>課程</li>
                      <li>研討會等</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>- 音響設備連 2 支無線咪</li>
                      <li>- 1 部投影機連銀幕</li>
                      <li>- 40 張椅</li>
                      <li>- 2 張乒乓球枱</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>舞蹈室</li>
                      <li>345 平方 呎</li>
                      <li>高度：2.2 米</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>訓練班</li>
                      <li>課程等</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>- 1 部電視連DVD機</li>
                      <li>- 音響設備連 1 支無線咪</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>會議室</li>
                      <li>167 平方呎</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>會議</li>
                      <li>課程</li>
                      <li>研討會等</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>- 4 張長枱</li>
                      <li>- 10 張椅</li>
                      <li>- 1 塊白板</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="pdf-download">
              <div>
                <img src={PDFICON}></img>{" "}
                <a target={"_blank"} href={PDF1}>
                  場地申請表格
                </a>
              </div>
              <div>
                <img src={PDFICON}></img>{" "}
                <a target={"_blank"} href={PDF2}>
                  租用守則
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default index;
