import React, { Component } from "react";
import { Container, Grid, Header, Segment, List } from "semantic-ui-react";
import "./index.scss";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Segment
        className="footer"
        inverted
        vertical
        style={{ padding: "5em 0em" }}
      >
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>
              <Grid.Column width={12}>
                <Header as="h4" inverted>
                  © Copyright © 2020 Wanchai Sports Federation Limited All
                  rights reserved
                </Header>
                {/* <p>Free to share, if you love it please bookmark, Thanks!</p> */}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    );
  }
}

export default index;
