import React, { Component } from "react";
import { Image, Icon } from "semantic-ui-react";
import PDFICON from "../../assets/icon/pdf.png";
import MenberPDF from "../../assets/pdf/灣仔體育總會有限公司-會員申請表.pdf";
import joinmember01 from "../../assets/images/joinmember.jpg";
import joinmember02 from "../../assets/images/joinmember02.jpg";
import img8 from "../../assets/images/8.jpeg";
import img9 from "../../assets/images/9.jpg";
import img10 from "../../assets/images/10.jpg";
import img11 from "../../assets/images/11.jpg";
import "./index.scss";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img: joinmember01,
    };
  }
  render() {
    const { img } = this.state;
    return (
      <div className="menber">
        <section id="menber-first-content" className="content">
          <h2>會員服務</h2>
          <h2 style={{ textAlign: "center" }}>歡迎您 加入灣仔體育總會</h2>
          <br />
          <div className="content">
            <div className="left">
              <p>成為會員，可享有以下權利：</p>

              <ul>
                <li>
                  <Icon name="checkmark" size="large" />
                  參加本會舉辦之訓練課程及興趣班
                </li>
                <li>
                  <Icon name="checkmark" size="large" />
                  租用本會場地設施
                </li>
                <li>
                  <Icon name="checkmark" size="large" />
                  優先報名本會主辦之各項活動
                </li>
              </ul>
            </div>
            <div className="right">
              <p>會員類別：</p>
              <ul>
                <li>永久會員</li>
                <li>普通會員 (每年收費)</li>
                <li>長者會員 (60嵗或以上長者)</li>
                <li>學生會員 (全日制學生)</li>
              </ul>
            </div>
          </div>
          <br />
          <div className="bottom">
            <a href={MenberPDF} target="_blank" className="download">
              <img
                src={img}
                onMouseOver={(e) => {
                  this.setState({
                    img: joinmember02,
                  });
                }}
                onMouseOut={(e) => {
                  this.setState({
                    img: joinmember01,
                  });
                }}
              ></img>
            </a>
          </div>
        </section>
        <section id="menber-images-content" className="content">
          <div className="images">
            <Image src={img8} />
            <Image src={img9} />
            <Image src={img10} />
            <Image src={img11} />
          </div>
        </section>
      </div>
    );
  }
}

export default index;
