import React from "react";

import Home from "../Layout/Home";
import AboutUsAim from "../Layout/AboutUsAim";
import Events from "../Layout/Events";
import Menber from "../Layout/Menber";
import SportsFacilities from "../Layout/SportsFacilities";
import AboutUs from "../Layout/AboutUs";
import { Redirect } from "react-router-dom";
const routes = [
  {
    exact: true,
    path: "/home",
    component: Home,
  },
  {
    exact: true,
    path: "/aboutus_aim",
    component: AboutUsAim,
  },
  {
    exact: true,
    path: "/events",
    component: Events,
  },
  {
    exact: true,
    path: "/sporta_facilities",
    component: SportsFacilities,
  },
  {
    exact: true,
    path: "/member",
    component: Menber,
  },
  {
    exact: true,
    path: "/find_us",
    component: AboutUs,
  },
  {
    path: "*",
    render: () => <Redirect to={"/home"} />,
  },

  //   {
  //     path: "/electronics",
  //     component: <div>123</div>,
  //     breadcrumbName: "Electronics",
  //     routes: [
  //       {
  //         path: "/electronics/mobile",
  //         component: <div>123</div>,
  //         breadcrumbName: "Mobile Phone"
  //       },
  //       {
  //         path: "/electronics/desktop",
  //         component: <div>123</div>,
  //         breadcrumbName: "Desktop PC"
  //       },
  //       {
  //         path: "/electronics/laptop",
  //         component: <div>123</div>,
  //         breadcrumbName: "Laptop"
  //       }
  //     ]
  //   }
];

export default routes;
