import React from "react";
import { isIE } from "react-device-detect";
// import { FacebookProvider, Like } from "react-facebook";
import Sponser from "../../Components/Sponcer";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
// import AwesomeSliderStyles from "react-awesome-slider/src/styles";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CoreStyles from "react-awesome-slider/src/core/styles.scss";
import AnimationStyles from "react-awesome-slider/src/styled/cube-animation/cube-animation.scss";
import "./index.scss";

import s1 from "../../assets/images/slider/1.jpg";
import s2 from "../../assets/images/slider/2.jpg";
import s3 from "../../assets/images/slider/3.jpg";
import s4 from "../../assets/images/slider/4.jpg";
import s5 from "../../assets/images/slider/5.jpg";
import s6 from "../../assets/images/slider/6.jpg";
import s7 from "../../assets/images/slider/7.jpg";
import s8 from "../../assets/images/slider/8.jpg";

const AutoplaySlider = withAutoplay(AwesomeSlider);
function Home(props) {
  console.log("[Home]", props);
  // window.addEventListener("resize", () => {
  //   if (document.getElementById("home-slider").firstChild) {
  //     document.getElementById("home-slider").firstChild.style.height =
  //       parseInt(document.getElementById("home-slider").offsetHeight) -
  //       45 +
  //       "px";
  //   }
  // });
  return (
    <div className="home">
      <section id="home-slider" className="content">
        {isIE && (
          <Carousel autoPlay showThumbs={false}>
            <div>
              <img alt="" src={s1} />
            </div>

            <div>
              <img alt="" src={s2} />
            </div>
            <div>
              <img alt="" src={s3} />
            </div>
            <div>
              <img alt="" src={s4} />
            </div>
            <div>
              <img alt="" src={s5} />
            </div>
            <div>
              <img alt="" src={s6} />
            </div>
            <div>
              <img alt="" src={s7} />
            </div>
            <div>
              <img alt="" src={s8} />
            </div>
          </Carousel>
        )}
        {!isIE && (
          <AutoplaySlider
            id="slider"
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={5000}
            cssModule={[CoreStyles, AnimationStyles]}
            animation={"cubeAnimation"}
            media={[
              {
                source: s1,
              },
              {
                source: s2,
              },
              {
                source: s3,
              },
              {
                source: s4,
              },
              {
                source: s5,
              },
              {
                source: s6,
              },
              {
                source: s7,
              },
              {
                source: s8,
              },
            ]}
          />
        )}
      </section>
      <section id="home-first-content" className="content">
        {/* <div className="left"> */}
        <h2>關於我們</h2>
        <p>
          灣仔體育總會於2001年6月成立，現在已發展為灣仔區內一個頗具規模及為政府正式認可的地區體育總會。本會有著健全的組織架構，成員來自熱心體育人士及地區體育人才，一直以拓展區內體育事業和培養體育人士為己任，多年來在灣仔民政事務處及灣仔區議會等部門的支持及撥款下，在區內積極推廣各類體育培訓及舉辦不同的競賽活動，其中田徑、羽毛球及游泳代表隊訓練計劃每年均為灣仔區培訓不少體育精英，代表灣仔區出戰區際比賽。足球方面，灣仔體育總會足球隊於2010/11年度連奪丙組地區足球聯賽冠軍及丙組聯賽總冠軍兩項錦標，因而在2011/12年度晉升乙組，同年更勇獲香港足球總會康寶初級銀牌賽冠軍。球隊續於香港足球總會舉辦的聯賽中角逐。{" "}
        </p>
        <p>
          本會於2013年正式註冊成為「灣仔體育總會有限公司」
          ，至2014年2月更獲稅務局批准成為根據稅務條例第88條豁免繳稅之慈善機構。其後獲地政總署批出銅鑼灣摩頓臺作為本會會所，並獲得香港賽馬會慈善信託基金贊助興建及裝修經費，於2014年11月正式遷入新會址。
        </p>
        <p>
          本會過往曾參加本會各項培訓、比賽及活動的人士已超過一萬人，成績來之不易。祈盼本會在社會各界的繼續鼓勵及支持下再接再厲，在灣仔舉辦更多元化的體育活動和培育更多體育人才，為灣仔區及體育界出一分力。
        </p>
        {/* </div> */}
        {/* <div className="right">
          <div className="images">
            <Image src={"https://via.placeholder.com/1024x768.png"} />
            <Image src={"https://via.placeholder.com/1024x768.png"} />
            <Image src={"https://via.placeholder.com/1024x768.png"} />
          </div>
        </div> */}
      </section>

      {/* <br />
      <FacebookProvider appId="255026555675291">
        <Like
          href="https://www.facebook.com/wanchaisports"
          colorScheme="dark"
          showFaces
          share
        />
      </FacebookProvider>
      <br /> */}

      <section id="home-sponser" className="content">
        {/* <Grid.Column width={3}>
                <Header inverted as="h4" content="Services" />
                <List link inverted>
                  <List.Item as="a">Banana Pre-Order</List.Item>
                  <List.Item as="a">DNA FAQ</List.Item>
                  <List.Item as="a">How To Access</List.Item>
                  <List.Item as="a">Favorite X-Men</List.Item>
                </List>
              </Grid.Column> */}
        <Sponser />
      </section>
      {/* <section id="home-site-map" className="content">
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header inverted as="h4" content="灣仔體育總會" />
              <List link inverted>
                <List.Item as="div">電話：2388 1875</List.Item>
                <List.Item as="div">地址：香港銅鑼灣摩頓臺</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <Header inverted as="h3" content="網頁地圖" />
              <List link inverted className="site-link-list">
                <List.Item as={NavLink} to="/aboutus_aim" exact={true}>
                  本會宗旨
                </List.Item>
                <List.Item as={NavLink} to="/events" exact={true}>
                  訓練課程及活動
                </List.Item>
                <List.Item as={NavLink} to="/sporta_facilities" exact={true}>
                  本會設施
                </List.Item>
                <List.Item as={NavLink} to="/member" exact={true}>
                  會員服務
                </List.Item>
                <List.Item as={NavLink} to="/find_us" exact={true}>
                  聯絡我們
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </section> */}
    </div>
  );
}
export default Home;
