import React, { Component, Suspense } from "react";
import routes from "./Router";
import { NavLink } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import {
  Container,
  Image,
  Menu,
  Sidebar,
  Icon,
  Responsive,
  Sticky,
} from "semantic-ui-react";
import Logo from "./assets/images/logo.png";
import Facebook from "./assets/images/fb.png";
import Mail from "./assets/images/mail.png";
import Footer from "./Components/Footer";
import "./App.scss";

const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { activeItem: "home", visible: false };
  }
  setVisible = (bool) => {
    this.setState({
      visible: bool,
    });
  };

  render() {
    const { activeItem, visible } = this.state;

    console.log(getWidth(), Responsive.onlyMobile.maxWidth);
    return (
      <Suspense fallback={<div>Loading</div>}>
        <Responsive
          className="mobile"
          getWidth={getWidth}
          maxWidth={Responsive.onlyMobile.maxWidth}
        >
          <Sidebar.Pushable>
            <Sidebar
              as={Menu}
              animation="push"
              icon="labeled"
              inverted
              onHide={() => this.setVisible(false)}
              vertical
              visible={visible}
              width="thin"
            >
              {/* <Menu.Item as={NavLink} to="/home" exact={true}>
                <Icon name="home" />
                首頁
              </Menu.Item>
              <Menu.Item as={NavLink} to="/aboutus" exact={true}>
                <Icon name="gamepad" />
                About Us
              </Menu.Item> */}
              <Menu.Item as={NavLink} name="首頁" to="/home" exact={true} />
              <Menu.Item
                as={NavLink}
                name="本會宗旨"
                to="/aboutus_aim"
                exact={true}
              />
              <Menu.Item
                as={NavLink}
                name="訓練課程及活動"
                to="/events"
                exact={true}
              />
              <Menu.Item
                as={NavLink}
                name="本會設施"
                to="/sporta_facilities"
                exact={true}
              />
              <Menu.Item
                as={NavLink}
                name="會員服務"
                to="/member"
                exact={true}
              />
              <Menu.Item
                as={NavLink}
                name="聯絡我們"
                to="/find_us"
                exact={true}
              />
            </Sidebar>

            <Sidebar.Pusher dimmed={visible}>
              <Container>
                <Menu secondary className="menu-header">
                  <Menu.Item>
                    <Image src={Logo} size="medium" />
                  </Menu.Item>
                </Menu>
                <Menu inverted size="large">
                  <Menu.Item onClick={() => this.setVisible(!visible)}>
                    <Icon name="sidebar" />
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/wanchaisports",
                        "_blank"
                      );
                    }}
                    position="right"
                  >
                    FB
                  </Menu.Item>
                </Menu>
              </Container>
              <Container className="body">
                {renderRoutes(routes, {
                  ...this.props,
                })}
              </Container>
              <Footer />
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Responsive>
        <Responsive
          className="desktop"
          getWidth={getWidth}
          minWidth={Responsive.onlyTablet.minWidth}
        >
          <Container>
            <Menu secondary className="menu-header">
              <Menu.Item>
                <Image as={NavLink} src={Logo} size="medium" to="/home" />
              </Menu.Item>

              <Menu.Item position="right">
                <Image
                  src={Facebook}
                  size="mini"
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/wanchaisports",
                      "_blank"
                    );
                  }}
                />
                <Image
                  src={Mail}
                  size="mini"
                  as={"a"}
                  href="mailto:info@wcsf.org.hk"
                />
              </Menu.Item>
            </Menu>
            <Menu inverted size="large">
              <Menu.Item as={NavLink} name="首頁" to="/home" exact={true} />
              <Menu.Item
                as={NavLink}
                name="本會宗旨"
                to="/aboutus_aim"
                exact={true}
              />

              <Menu.Item
                as={NavLink}
                name="訓練課程及活動"
                to="/events"
                exact={true}
              />
              <Menu.Item
                as={NavLink}
                name="本會設施"
                to="/sporta_facilities"
                exact={true}
              />
              <Menu.Item
                as={NavLink}
                name="會員服務"
                to="/member"
                exact={true}
              />
              <Menu.Item
                as={NavLink}
                name="聯絡我們"
                to="/find_us"
                exact={true}
              />
            </Menu>
          </Container>
          <Container className="body">
            {renderRoutes(routes, {
              ...this.props,
            })}
          </Container>
          <Footer />
        </Responsive>
      </Suspense>
    );
  }
}

export default App;
