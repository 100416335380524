import React, { Component } from "react";
import { Image } from "semantic-ui-react";
import "./index.scss";
import img1 from "../../assets/images/1.jpg";
import img2 from "../../assets/images/2.jpg";
import img3 from "../../assets/images/3.jpg";
import img4 from "../../assets/images/4.jpg";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="aboutusaim">
        <section id="aboutusaim-first-content" className="content">
          <div className="left">
            <h2>本會宗旨</h2>
            <br />
            <br />
            <br />
            <p>弘揚體育精神，促進健康身心。</p>
            <p>推廣體育活動，鼓勵居民參與。</p>
            <p>培訓體育人才，提升運動水平。</p>
            <p>選拔體育代表，參加各項競賽。</p>
            <p>造就體育健兒，齊為灣仔增光。</p>
          </div>
          <div className="right">
            <div className="images">
              <Image src={img1} />
              <Image src={img2} />
              <Image src={img3} />
              <Image src={img4} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default index;
