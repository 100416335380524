import React, { Component } from "react";
import { Image } from "semantic-ui-react";
import HKJC from "../../assets/images/Trust_LogoH_Funded_LBk.jpg";
import Galewell_logo from "../../assets/images/Galewell_logo.png";
import hhl_logo_chn_eng_name from "../../assets/images/hhl_logo_chn_eng_name.jpg";
import "./index.scss";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Image.Group size="small" className="sponser">
        <Image src={HKJC} />
        <Image src={Galewell_logo} />
        <Image src={hhl_logo_chn_eng_name} />
      </Image.Group>
    );
  }
}

export default index;
