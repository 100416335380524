import React, { Component } from "react";
import { FacebookProvider, Like, Page } from "react-facebook";
import img1 from "../../assets/images/12.jpg";
import img2 from "../../assets/images/13.jpg";
import img3 from "../../assets/images/14.jpg";
import img4 from "../../assets/images/15.jpg";
import img5 from "../../assets/images/16.jpg";
import img6 from "../../assets/images/17.jpeg";
import img7 from "../../assets/images/18.jpg";
import img8 from "../../assets/images/19.jpg";
import img9 from "../../assets/images/20.jpg";
import img10 from "../../assets/images/21.jpg";
import img11 from "../../assets/images/22.jpg";
import img12 from "../../assets/images/23.jpg";
import img13 from "../../assets/images/24.jpg";
import img14 from "../../assets/images/25.jpg";
import "./index.scss";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="events">
        <section id="events-first-content" className="content">
          <h2>訓練課程及活動</h2>
          <p>
            本會舉辦各項多元化之訓練課程及康體活動，包括各項球類、田徑、游泳、瑜伽、普拉提、舞蹈、花式跳繩等，歡迎有興趣人士聯絡我們查詢。另外，亦請追蹤本會Facebook專頁，以獲得本會訓練課程及活動的最新消息。
          </p>
        </section>
        <section id="events-sec-content" className="content">
          <div>
            <FacebookProvider appId="255026555675291">
              <Page
                href="https://www.facebook.com/wanchaisports"
                tabs="timeline"
              />
            </FacebookProvider>
          </div>
          <div className="row">
            <div className="column">
              <div className="image">
                <img src={img3} style={{ width: "100%" }} />
                <div className="title">田徑代表隊訓練計劃</div>
              </div>
              <div className="image">
                <img src={img4} style={{ width: "100%" }} />
                <div className="title">小型足球比賽</div>
              </div>
              <div className="image">
                <img src={img7} style={{ width: "100%" }} />
                <div className="title">花式跳繩班</div>
              </div>
            </div>
            <div className="column">
              <div className="image">
                <img src={img1} style={{ width: "100%" }} />
                <div className="title">軟式壘球班</div>
              </div>
              <div className="image">
                <img src={img5} style={{ width: "100%" }} />
                <div className="title">分齡游泳比賽</div>
              </div>
              <div className="image">
                <img src={img12} style={{ width: "100%" }} />
                <div className="title">羽毛球代表隊訓練計劃</div>
              </div>
            </div>
            <div className="column">
              <div className="image">
                <img src={img2} style={{ width: "100%" }} />
                <div className="title">分齡田徑比賽</div>
              </div>

              <div className="image">
                <img src={img6} style={{ width: "100%" }} />
                <div className="title">游泳代表隊訓練計劃</div>
              </div>
            </div>
          </div>
        </section>
        <section id="events-thr-content" className="content">
          <div className="row">
            <div className="column">
              <div className="image">
                <img src={img10} style={{ width: "100%" }} />
                <div className="title">青少年足球訓練計劃</div>
              </div>
              <div className="image">
                <img src={img8} style={{ width: "100%" }} />
                <div className="title">有氧健康舞班</div>
              </div>
            </div>

            <div className="column">
              <div className="image">
                <img src={img9} style={{ width: "100%" }} />
                <div className="title">羽毛球苗圃訓練及分齡比賽</div>
              </div>
              <div className="image">
                <img src={img13} style={{ width: "100%" }} />
                <div className="title">長跑活動</div>
              </div>
            </div>
            <div className="column">
              <div className="image">
                <img src={img11} style={{ width: "100%" }} />
                <div className="title">乒乓球比賽</div>
              </div>
              <div className="image">
                <img src={img14} style={{ width: "100%" }} />
                <div className="title">飛鏢體驗班</div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default index;
